import { ListGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";

export function JobCardView({ job, goToJobDetail }) {
  return (
    <Card style={{ width: "22rem", marginTop: "20px", marginLeft:'10px',marginBottom:'10px' }}>
      <Card.Header>{job.profile_name}</Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item>{job.company_name}</ListGroup.Item>
        <ListGroup.Item>
          Experience: {job.exp_from}-{job.exp_to}
        </ListGroup.Item>
        <ListGroup.Item>Looking for: {job.looking_for}</ListGroup.Item>
        <ListGroup.Item>Location: {job.location}</ListGroup.Item>
      </ListGroup>
      <Card.Footer>
        <Card.Link href={job.apply_link}>apply</Card.Link>
        <Card.Link onClick={() => goToJobDetail(job)}>job details</Card.Link>
      </Card.Footer>
    </Card>
  );
}
