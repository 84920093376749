import {
  addDoc,
  collection,
  query,
  getDocs,
  where,
} from "@firebase/firestore";
import {job_to_json } from "../jobs_frame_models/job_model";
import { db } from "./firebase";

const JOB_COLLECTION = "jobs";

export function createJob(job) {
    addDoc(collection(db, JOB_COLLECTION), job_to_json(job));
}

export async function getJobs(search_props) {
  const query_constraints = [];
  const jobs = [];

  if (search_props.search_company) {
    query_constraints.push(
      where("company_name", "==", search_props.search_company)
    );
  }

  if (search_props.search_exp) {
    query_constraints.push(where("exp_from", "<=", search_props.search_exp));
  }

  if (search_props.search_location) {
    query_constraints.push(
      where("location", "==", search_props.search_location)
    );
  }

  if (search_props.search_profile) {
    query_constraints.push(
      where("profile_name", "==", search_props.search_profile)
    );
  }

  if (
    search_props.search_category &&
    search_props.search_label !== "all jobs"
  ) {
    query_constraints.push(
      where("job_categories", "array-contains", search_props.search_category)
    );
  }

  const new_query = query(collection(db, JOB_COLLECTION), ...query_constraints);
  const querySnapshot = await getDocs(new_query);

  querySnapshot.forEach((doc) => {
    jobs.push(doc.data());
  });

  return jobs;
}
