// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBiOYm1D4-Vr3YwjH5nVoQwkl4sM1O6OZY",
  authDomain: "jobs-frame.firebaseapp.com",
  projectId: "jobs-frame",
  storageBucket: "jobs-frame.appspot.com",
  messagingSenderId: "789200195315",
  appId: "1:789200195315:web:c787d6f29f66c4d4e53186",
  measurementId: "G-284MDHG6BL"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
