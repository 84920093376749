import { useState, useEffect } from "react";
import { Pagination, Row } from "react-bootstrap";
import { JOB_PER_PAGE, pagination_list_size } from "../constants";
import { JobCardView } from "./job_components/job_card";

export function HomePage({ jobs, goToJobDetail }) {
  const [page_values, setPageValues] = useState([]);
  const [page_active_value, setPageActiveValue] = useState(1);
  const [display_jobs,setDisplayJobs] = useState([]);
  const [pagination_list,setPaginationList] = useState([]);

  useEffect(()=>{
    let start = Math.max(0,page_active_value - pagination_list_size/2);
    let end = Math.min(pagination_list.length,page_active_value + pagination_list_size/2);
    let arr = [];
    for(let i = start; i < end;i++){
      arr.push(pagination_list[i]);
    }
    setPageValues(arr);
    
  },[page_active_value,pagination_list]);

  useEffect(()=>{
    let job_list = [];
    for(let i = (page_active_value - 1)*JOB_PER_PAGE;i < page_active_value*JOB_PER_PAGE && jobs[i];i++){
      job_list.push(jobs[i]);
    }
    setDisplayJobs(job_list);
  },[jobs,page_active_value]);

  useEffect(() => {
    let number_of_jobs = jobs.length;
    let ceil = Math.ceil(number_of_jobs / JOB_PER_PAGE);
    let arr = [];
    for (let i = 1; i <= ceil; i++) {
      arr.push(i);
    }
    setPaginationList(arr);
    setPageValues(arr);
    setPageActiveValue(1);
  }, [jobs]);

  return (
    <div style={{ margin: "12px" }}>
      <Row xs={1} md={2} lg={3} className="g-4">
        {display_jobs.map((job, index) => {
          return (
            <JobCardView key={index} job={job} goToJobDetail={goToJobDetail} />
          );
        })}
      </Row>
      <Pagination>
        {page_values.map((page, index) => {
          return (
            <Pagination.Item
              key={index}
              active={page === page_active_value}
              onClick={() => setPageActiveValue(page)}
            >
              {page}
            </Pagination.Item>
          );
        })}
      </Pagination>
    </div>
  );
}
