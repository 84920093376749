import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "./App.css";
import { getJobs } from "./firebase/firestore";
import { HomePageSearch } from "./job_pages/job_components/home_page_search";
import {
  JobFooter,
  JobHeader,
} from "./job_pages/job_components/job_header_footer";
import { DetailsPage } from "./job_pages/job_details_page";
import { HomePage } from "./job_pages/job_home_page";

function App() {
  const [homeDetailsPageToggle, setHomeDetailsPageToggle] =
    useState("home_page");
  const [jobs, setJobs] = useState([]);
  const [job_detail, setJobDetail] = useState();
  const [job_fetch_status, setFetchStatus] = useState(false);

  const searchJob = async(search_props) => {
    setFetchStatus(false);
    setJobs(await getJobs(search_props));
    setHomeDetailsPageToggle("home_page");
    setFetchStatus(true);
  };

  const backToHome = () => {
    setFetchStatus(false);
    setHomeDetailsPageToggle("home_page");
    setFetchStatus(true);
  };

  const goToJobDetail = (job) => {
    setFetchStatus(false);
    setJobDetail(job);
    setHomeDetailsPageToggle("detail_page");
    setFetchStatus(true);
  };

  useEffect(() => {
    setFetchStatus(false);
    searchJob({search_category:"all jobs"});
    setFetchStatus(true);
  }, []);

  if (!job_fetch_status) {
    return (
      <div className="App">
        <JobHeader />
        <HomePageSearch/>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <JobFooter />
      </div>
    );
  }

  return (
    <div className="App">
      <JobHeader />
      <HomePageSearch searchJob={searchJob}/>
      {homeDetailsPageToggle === "home_page" && (
        <HomePage
          jobs={jobs}
          goToJobDetail={goToJobDetail}
        />
      )}
      {homeDetailsPageToggle === "detail_page" && (
        <DetailsPage job={job_detail} back={backToHome}  />
      )}

      {jobs.length === 0 && <div>job is not available</div>}
      <JobFooter />
    </div>
  );
}

export default App;
