
import { Dialog } from "@mui/material";
import { EmailAuthProvider, GoogleAuthProvider, onAuthStateChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { StyledFirebaseAuth } from "react-firebaseui";
import { auth } from "../firebase/firebase";
import { createJob } from "../firebase/firestore";
import { Job } from "../jobs_frame_models/job_model";
import { JobCardView } from "./job_components/job_card";
import { JobFooter, JobHeader } from "./job_components/job_header_footer";

export function AboutUs() {
  const [login,setLogin] = useState(false);
  const [authUser,setAuthUser] = useState();
  const [newJobs,setNewJobs] = useState([]);

  useEffect(()=>{
    console.log('auth user effect');
    const unsubscribe = onAuthStateChanged(auth,()=>{
      setAuthUser(auth.currentUser);
     });
    return ()=> unsubscribe();

},[]);
  const REDIRECT_PAGE = '/about';

  // Configure FirebaseUI.
  const uiConfig = {
    signInFlow: 'popup', // popup signin flow rather than redirect flow
    signInSuccessUrl: REDIRECT_PAGE,
    signInOptions: [
      EmailAuthProvider.PROVIDER_ID,
      GoogleAuthProvider.PROVIDER_ID,
    ],
  };

  const readFile = (e)=>{
    let data = JSON.parse(e.currentTarget.result) ;
    setNewJobs(data.map((data)=>{
      return new Job(data.id, data.company_name, data.profile_name, 
        data.looking_for, data.description, data.exp_from, data.exp_to, data.location, data.post_date, 
        data.apply_link, ["all jobs"], data.job_family);
    }));
    console.log(newJobs);
  }

  

  const JobFile = (event) => {
    console.log(event.target.files[0]);
    let fileReader = new FileReader();
    fileReader.onloadend = readFile;
    fileReader.readAsText(event.target.files[0]);
    
  }

  const uploadJobs = ()=>{
    newJobs.forEach((job)=>{
      console.log(job);
      createJob(job);
    });
  }
  return (
    <div>
      <JobHeader />
      <Dialog open = {login} onClose = {()=> setLogin(false)}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth = {auth}>

      </StyledFirebaseAuth>
      </Dialog>

      {
        authUser ?  <Button  style = {{margin:"20px"}}  onClick={()=>signOut(auth)}>SignOut</Button> :
        <Button style = {{margin:"20px"}}  onClick={()=>setLogin(true)}>SignIn</Button>
      }
      <input type="file" onChange={JobFile}/>

      <Button style={{margin:"20px"}} onClick={uploadJobs}>upload jobs</Button>
      <Row xs={1} md={2} lg={3} className="g-4">
        {newJobs.map((job, index) => {
          return (
            <JobCardView key={index} job={job}  />
          );
        })}
      </Row>
      
      <JobFooter />
    </div>
  );
}
