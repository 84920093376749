import { Nav, Navbar, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export function JobHeader() {

  return (
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Jobsframe</Navbar.Brand>
          <Nav className="me-auto">
            {/* <Nav.Link href="/">Home</Nav.Link> */}
            {/* <Nav.Link href="/about">Contact Us</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link> */}
          </Nav>
        </Container>
      </Navbar>
  );
}

export function JobFooter() {
  return (
    <Nav
      style={{
        paddingTop: "12px",
        paddingBottom: "12px",
        backgroundColor: "black",
        marginTop: "12px",
      }}
    >
      <Nav.Link
        href="https://www.youtube.com/channel/UC02KUlA-Sqn8lwY2tt4386A"
        className="youtube social"
      >
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </Nav.Link>
      <Nav.Link
        href="https://www.facebook.com/jobs.frame.3/about"
        className="facebook social"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </Nav.Link>
      <Nav.Link
        href="https://www.linkedin.com/company/jobs-frame/"
        className="LinkedIn social"
      >
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </Nav.Link>
      <Nav.Link
        href="https://www.instagram.com/jobsframe/"
        className="instagram social"
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </Nav.Link>
    </Nav>
  );
}
