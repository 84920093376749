import { useState } from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import { LIST_OF_CATEGORIES } from "../../constants";

export function JobCategoriesNavigation({setSearchCategory,searchJob}) {
  const [activeCategory,setActiveCategory] = useState("all jobs");

  return (
    <div >
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand>Categories</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav variant="pills" defaultActiveKey="#all jobs">
              {LIST_OF_CATEGORIES.map((category,index) => {
                return (
                  <Nav.Link
                    active = {activeCategory === category.label_name}
                    key = {index}
                    style={{ marginLeft: "10px" ,marginTop:"10px"}}
                    onClick={() => {
                      setSearchCategory(category.label_name);
                      setActiveCategory(category.label_name);
                      searchJob({search_category:category.label_name});
                    }}
                  >
                    {category.label_name}
                  </Nav.Link>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
