import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import { JobDetails } from "./job_components/job_detail";

export function DetailsPage({ job, back }) {
  return (
    <div>
      <Card style={{ margin: "12px" }}>
        <Card.Header>
          <Nav>
            <Button
              style={{ marginLeft: "12px" }}
              variant="primary"
              onClick={() => back()}
            >
              Back
            </Button>
            <Button
              variant="primary"
              style={{ marginLeft: "12px" }}
              href={job.apply_link}
            >
              Apply
            </Button>
          </Nav>
        </Card.Header>
        <Card.Body>
          <Card.Title>{job.profile_name}</Card.Title>
          <Card.Subtitle>{job.company_name}</Card.Subtitle>
          <Card.Text>
            <JobDetails description={job.description} />
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Nav>
            <Nav.Link disabled>Location : {job.location}</Nav.Link>
            <Nav.Link disabled>Post Date : {job.post_date}</Nav.Link>
            <Nav.Link disabled>
              Experience : {job.exp_from + " - " + job.exp_to}
            </Nav.Link>
          </Nav>
        </Card.Footer>
      </Card>
    </div>
  );
}
