import { useEffect, useState } from "react";
import { Button, Dropdown, Form, Navbar } from "react-bootstrap";
import { EXP_DROPDOWN } from "../../constants";
import { JobCategoriesNavigation } from "./job_navigation";

export function HomePageSearch({ searchJob }) {
  const [search_exp, setSearchExp] = useState();
  const [search_company, setSearchCompany] = useState("");
  const [search_profile, setSearchProfile] = useState("");
  const [search_location, setSearchLocation] = useState("");
  const [search_category, setSearchCategory] = useState("all jobs");

 
  useEffect(()=>{
    setSearchCompany(null);
    setSearchProfile(null);
    setSearchLocation(null);
  },[search_category]);

  return (
    <div>
      <JobCategoriesNavigation
        setSearchCategory={setSearchCategory}
        searchJob={searchJob}
      />
      <Navbar
        bg="light"
        expand="lg"
        style={{ marginTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search by Company"
            className="me-2"
            aria-label="Search"
            onChange={(e) => setSearchCompany(e.target.value)}
          />
          <Form.Control
            type="search"
            placeholder="Search by Profile"
            className="me-2"
            aria-label="Search"
            onChange={(e) => setSearchProfile(e.target.value)}
          />
          <Form.Control
            type="search"
            placeholder="Search by location"
            className="me-2"
            aria-label="Search"
            onChange={(e) => setSearchLocation(e.target.value)}
          />

          <Dropdown style={{ marginRight: "12px" }}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Experience - {search_exp}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {EXP_DROPDOWN.map((exp, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      if (exp === "None") {
                        exp = null;
                      }
                      setSearchExp(exp);
                    }}
                  >
                    {exp}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Button
            variant="outline-success"
            onClick={() =>
              searchJob({
                search_company,
                search_location,
                search_profile,
                search_exp,
                search_category,
              })
            }
          >
            Search
          </Button>
        </Form>
      </Navbar>
    </div>
  );
}
