export const LIST_OF_CATEGORIES = [
  {label_name:'all jobs'},
  {label_name:'private jobs'},
  {label_name:'Government jobs'},
  {label_name:'Tech jobs'},
  {label_name:'Non Tech jobs'},
  {label_name:'Internship'},
  {label_name:'Part Time jobs'}];

export const EXP_DROPDOWN = ['None',1,2,3,4,5,6,7,8,9,10];
export const JOB_PER_PAGE = 20;
export const pagination_list_size = 10;

