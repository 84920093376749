export class Job {
    constructor(job_id,company_name,profile_name,looking_for,description,exp_from,exp_to,location,post_date,apply_link,job_categories,job_family){
        this.job_id = job_id;
        this.company_name = company_name;
        this.profile_name = profile_name;
        this.looking_for = looking_for;
        this.description = description;
        this.exp_from = exp_from;
        this.exp_to = exp_to;
        this.location = location;
        this.post_date = post_date;
        this.apply_link = apply_link;
        this.job_categories = job_categories;
        this.job_family = job_family;
    }
}

export function job_to_json(job){
    return {
        'job_id' : job.job_id,
        'company_name' : job.company_name,
        'profile_name' : job.profile_name,
        'looking_for' : job.looking_for,
        'description' : job.description,
        'exp_from' : job.exp_from,
        'exp_to' : job.exp_to,
        'location' : job.location,
        'post_date' : job.post_date,
        'apply_link' : job.apply_link,
        'job_categories':job.job_categories
    }
}
